@import url('https://fonts.googleapis.com/css?family=Roboto:400,400i,500,500i,700,700i,900,900i&display=swap');

:root {
  --bg-img: url(https://res.cloudinary.com/stegault/image/upload/q_auto:eco/v1559727685/85004.webp);
  --bg-white: #ffffff;
  --bg-black: #000000;
  --bg-grey: #efefef;
  --bg-grey-darken: #b9b9b9;
  --bg-transparent: transparent;

  --c-font: #212529;
  --c-white: #ffffff;
  --border-black: #000000;
  --border-white: #e7e7e7;
  --theme-1: #1c8554;
  --theme-2: #2ea06b;
  --black: #000000;
  --instagram: #d6249f;
  --linkedin: #217bc1;
  --twitter: #35ccff;
  --sunset-red: #fd5e53;
  --sunset-orange: #ff8c4c;
  --sunset-yellow: #fed574;
  --ps-red: #ff1900;
  --ps-blue: #0045ab;
  --ps-lightblue: #007f97;
  --ps-yellow: #ff9a00;
  --ps-gradient: linear-gradient(
    var(--ps-blue),
    var(--ps-yellow),
    var(--ps-red)
  ) !important;
  --funky-gradient: linear-gradient(40deg, #ff6ec4, #7873f5) !important;
}

html {
  font-size: 16px;
}

html,
body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
}

html,
body,
header {
  height: auto;
}

#instaAccount {
  transition: all 0.2s ease-in-out;
}
#instaAccount:hover {
  cursor: pointer;
  transform: scale(1.1);
}

.avatar img:hover {
  cursor: pointer;
}

.bg-b {
  background-color: var(--bg-black);
}

.bg--funky {
  background-image: var(--funky-gradient);
}

.bg-g--darken {
  background-color: var(--bg-grey-darken);
}

.bg-g {
  background-color: var(--bg-grey);
}

.bg-w {
  background-color: var(--bg-white);
}

.bounce {
  animation: bounce 2s;
  animation-delay: 5s;
  animation-iteration-count: 5;
}

@keyframes bounce {
  0%,
  25%,
  50%,
  75%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-13px);
  }
  60% {
    transform: translateY(-6px);
  }
}

.btn--no-style,
.nav-link .btn--no-style {
  background-color: var(--bg-transparent) !important;
  box-shadow: none !important;
  margin: 0 !important;
  padding: 0 !important;
}

.btn-theme-1 {
  background-color: var(--theme-1) !important;
  color: var(--c-white);
}

.c-t--font {
  color: var(--c-font) !important;
}

.card {
  background-clip: border-box;
  background-color: var(--c-white);
  border: 0;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  display: flex;
  flex-direction: column;
  font-weight: 400;
  min-width: 0;
  position: relative;
  word-wrap: break-word;
}

.card-body .btn-unique {
  background-color: var(--theme-2) !important;
  color: var(--c-white);
}

.carousel-caption {
  color: var(--c-font) !important;
}

.dropdown-toggle::after {
  margin-left: 0.55em;
  vertical-align: 0.1em;
}

.fa:hover {
  transition: all linear 0.3s;
}
.fa-facebook-f:hover {
  color: #03539e;
}
.fa-twitter:hover {
  color: var(--twitter);
}
.fa-linkedin-in:hover {
  color: var(--linkedin);
}
.fa-google-plus-square:hover {
  color: #dd4b39;
}
.fa-github:hover {
  color: var(--sunset-red);
}
.fa-pinterest-square:hover {
  color: #cb2027;
}
.fa-instagram:hover {
  color: var(--instagram);
}
.fa-youtube-play:hover {
  color: #e52d27;
}

.fa-playstation:hover {
  color: var(--ps-blue);
}

.fa-chevron-circle-down:hover {
  background: var(--funky-gradient);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-image: var(--funky-gradient);
  border-radius: 50%;
}

.funky-font {
  background: var(--funky-gradient);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-image: var(--funky-gradient);
}

.gradient {
  background: -moz-linear-gradient(
    45deg,
    rgba(253, 94, 83, 0.6),
    rgba(255, 140, 76, 0.6),
    rgba(254, 213, 113, 0.69)
  );
  background: -webkit-linear-gradient(
    45deg,
    rgba(253, 94, 83, 0.6),
    rgba(255, 140, 76, 0.6),
    rgba(254, 213, 113, 0.69)
  );
  background: linear-gradient(
    45deg,
    rgba(253, 94, 83, 0.6),
    rgba(255, 140, 76, 0.6),
    rgba(254, 213, 113, 0.69)
  );
}

.h-100vh {
  height: 100vh;
  max-height: 100vh;
}

.pop {
  transition: all 0.5s ease-in-out;
}

.pop:hover {
  transform: scale(1.1);
}

.navbar:not(.top-nav-collapse) {
  background: transparent !important;
}

.navbar-brand {
  font-size: 1rem !important;
}

.rgba-gradient {
  background: rgba(35, 7, 77, 0.6);
  /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    rgba(204, 83, 51, 0.6),
    rgba(35, 7, 77, 0.6)
  );
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    rgba(204, 83, 51, 0.6),
    rgba(35, 7, 77, 0.6)
  );
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.slider--height {
  max-height: 250px;
}

.top-nav-collapse,
.page-footer {
  background-image: var(--funky-gradient);
}

.view--bg {
  background-image: var(--bg-img);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: 100vh;
}

@media (max-width: 740px) {
  html,
  body {
    height: 100vh;
  }

  .navbar-collapse .social__icons--row {
    flex-direction: row;
  }
}

@media (max-width: 991px) {
  .navbar:not(.top-nav-collapse) {
    background: var(--theme-1) !important;
  }

  .navbar-collapse .social__icons--row {
    flex-direction: row;
  }
}

@media (max-width: 767px) {
  .hidden-sm-down {
    display: none;
  }
}
